import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Image, Structure, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, StackItem, Stack } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Gadget Guru Repairs
			</title>
			<meta name={"description"} content={"Your reliable partner in equipment repair"} />
			<meta property={"og:title"} content={"Gadget Guru Repairs"} />
			<meta property={"og:description"} content={"Your reliable partner in equipment repair"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/mobile-phone-repair.jpg?v=2024-06-13T09:31:00.740Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/mobile-phone-repair.jpg?v=2024-06-13T09:31:00.740Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/mobile-phone-repair.jpg?v=2024-06-13T09:31:00.740Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/mobile-phone-repair.jpg?v=2024-06-13T09:31:00.740Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/mobile-phone-repair.jpg?v=2024-06-13T09:31:00.740Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/mobile-phone-repair.jpg?v=2024-06-13T09:31:00.740Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/mobile-phone-repair.jpg?v=2024-06-13T09:31:00.740Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header>
			<Override slot="text" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2">
				Contacts
			</Override>
		</Components.Header>
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://uploads.quarkly.io/66a246642fc7070018ba356d/images/images1204-5c9b388b0066b.jpg?v=2024-07-25T12:51:47.461Z) 80%/cover scroll"
			padding="120px 0 120px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
			min-height="70vh"
		>
			<Override slot="SectionContent" height="auto" justify-content="center" />
			<Stack>
				<StackItem width="75%" lg-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Text as="h1" font="--headline2" md-font="--headline2" margin="10px 0">
						Your Reliable Partner in Device Repair
					</Text>
				</StackItem>
			</Stack>
			<Text color="--lightD2" letter-spacing="1px" text-transform="uppercase" margin="0">
				WE STRIVE TO PROVIDE YOU WITH THE HIGHEST QUALITY SERVICE, SPEED, AND RELIABILITY.
			</Text>
		</Section>
		<Section padding="60px 0 60px 0" md-padding="30px 0 30px 0" border-color="--color-secondary">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				padding="0px 90px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				width="50%"
				lg-padding="0px 28px 0px 0px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				align-self="center"
			>
				<Text
					font="--headline5"
					lg-font="normal 500 32px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					margin="0px 0px 32px 0px"
					color="--light"
				>
					"Gadget Guru Repairs" is a team of highly qualified specialists with many years of experience in device repair.
				</Text>
				<Text
					color="--light"
					font="--base"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 16px 0px"
					margin="0px 0px 0px 0px"
				>
					We work with various household devices, including computers, smartphones, and home and entertainment appliances. Our craftsmen constantly improve their knowledge and skills to keep up with the latest innovations and technologies.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					src="https://uploads.quarkly.io/66a246642fc7070018ba356d/images/Reasons-to-Choose-Local-Cell-Phone-Repair-Shops.jpeg?v=2024-07-25T12:51:47.478Z"
					width="100%"
					max-height="100%"
					object-fit="cover"
					lg-min-height="300px"
					md-max-height="277px"
					md-min-height="16px"
					height="100%"
					object-position="bottom"
					box-shadow="--xl"
					border-radius="30px"
					srcSet="https://smartuploads.quarkly.io/66a246642fc7070018ba356d/images/Reasons-to-Choose-Local-Cell-Phone-Repair-Shops.jpeg?v=2024-07-25T12%3A51%3A47.478Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66a246642fc7070018ba356d/images/Reasons-to-Choose-Local-Cell-Phone-Repair-Shops.jpeg?v=2024-07-25T12%3A51%3A47.478Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66a246642fc7070018ba356d/images/Reasons-to-Choose-Local-Cell-Phone-Repair-Shops.jpeg?v=2024-07-25T12%3A51%3A47.478Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66a246642fc7070018ba356d/images/Reasons-to-Choose-Local-Cell-Phone-Repair-Shops.jpeg?v=2024-07-25T12%3A51%3A47.478Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66a246642fc7070018ba356d/images/Reasons-to-Choose-Local-Cell-Phone-Repair-Shops.jpeg?v=2024-07-25T12%3A51%3A47.478Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66a246642fc7070018ba356d/images/Reasons-to-Choose-Local-Cell-Phone-Repair-Shops.jpeg?v=2024-07-25T12%3A51%3A47.478Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66a246642fc7070018ba356d/images/Reasons-to-Choose-Local-Cell-Phone-Repair-Shops.jpeg?v=2024-07-25T12%3A51%3A47.478Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Structure cells-number-total="3" cells-number-group="3">
			<Override slot="Content" grid-template-columns="9fr 3fr" md-grid-template-columns="repeat(6, 2fr)" sm-grid-template-columns="12fr">
				<Override
					slot="Cell 0th"
					grid-column="1 / auto"
					grid-row="auto / span 2"
					md-grid-column="1 / span 6"
					md-grid-row="span"
					sm-grid-column="auto"
					sm-grid-row="span"
				/>
				<Override slot="Cell 1st" md-grid-column="1 / span 3" sm-grid-column="auto" align-self="end" />
				<Override slot="Cell 2nd" md-grid-column="4 / span 3" sm-grid-column="auto" align-self="end" />
				<Override slot="cell-0">
					<Text
						margin="0px 0px 24px 0px"
						color="#ffffff"
						font="--headline2"
						sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
						text-transform="uppercase"
					>
						OUR SERVICES
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="--base"
						color="--light"
						text-align="left"
						lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
						md-margin="0px 0px 32px 0px"
					>
						We offer a wide range of services related to device repair and maintenance
					</Text>
					<Image
						src="https://uploads.quarkly.io/66a246642fc7070018ba356d/images/phone-repairs-e1591134173247-1024x512.jpeg?v=2024-07-25T12:51:47.460Z"
						display="block"
						max-height="100%"
						max-width="80%"
						box-shadow="--xl"
						border-radius="30px"
						margin="2rem 0px 0px 0px"
						srcSet="https://smartuploads.quarkly.io/66a246642fc7070018ba356d/images/phone-repairs-e1591134173247-1024x512.jpeg?v=2024-07-25T12%3A51%3A47.460Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66a246642fc7070018ba356d/images/phone-repairs-e1591134173247-1024x512.jpeg?v=2024-07-25T12%3A51%3A47.460Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66a246642fc7070018ba356d/images/phone-repairs-e1591134173247-1024x512.jpeg?v=2024-07-25T12%3A51%3A47.460Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66a246642fc7070018ba356d/images/phone-repairs-e1591134173247-1024x512.jpeg?v=2024-07-25T12%3A51%3A47.460Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66a246642fc7070018ba356d/images/phone-repairs-e1591134173247-1024x512.jpeg?v=2024-07-25T12%3A51%3A47.460Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66a246642fc7070018ba356d/images/phone-repairs-e1591134173247-1024x512.jpeg?v=2024-07-25T12%3A51%3A47.460Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66a246642fc7070018ba356d/images/phone-repairs-e1591134173247-1024x512.jpeg?v=2024-07-25T12%3A51%3A47.460Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Override>
				<Override slot="cell-1">
					<Text
						margin="32px 0px 16px 0px"
						font="--headline4"
						color="--green"
						lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
						md-margin="12px 0px 16px 0px"
						sm-margin="24px 0px 16px 0px"
					>
						Software Installation and System Updates
					</Text>
				</Override>
				<Override slot="cell-2">
					<Text
						sm-margin="24px 0px 16px 0px"
						margin="32px 0px 16px 0px"
						font="--headline4"
						color="--green"
						lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
						md-margin="12px 0px 16px 0px"
					>
						Maintenance and Configuration of Network Devices
					</Text>
				</Override>
			</Override>
		</Structure>
		<Structure cells-number-total="3" cells-number-group="3">
			<Override slot="Content" grid-template-columns="repeat(3, 4fr)" sm-grid-template-columns="12fr">
				<Override slot="cell-0">
					<Text
						sm-margin="24px 0px 16px 0px"
						margin="32px 0px 16px 0px"
						font="--headline4"
						color="--green"
						lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
						md-margin="12px 0px 16px 0px"
					>
						Computer and Laptop Repair
					</Text>
				</Override>
				<Override slot="cell-1">
					<Text
						sm-margin="24px 0px 16px 0px"
						margin="32px 0px 16px 0px"
						font="--headline4"
						color="--green"
						lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
						md-margin="12px 0px 16px 0px"
					>
						Smartphone and Tablet Repair
					</Text>
				</Override>
				<Override slot="cell-2">
					<Text
						margin="32px 0px 16px 0px"
						font="--headline4"
						color="\n\n--green"
						lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
						md-margin="12px 0px 16px 0px"
						sm-margin="24px 0px 16px 0px"
					>
						Home Appliance Repair
					</Text>
				</Override>
			</Override>
		</Structure>
		<Section padding="36px 0 60px 0" quarkly-title="HeroBlock" md-padding="16px 0 30px 0" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-border-color="LightGray"
				width="100%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
			>
				<Text
					font="--headline2"
					color="--light"
					margin="0px 0px 0px 0px"
					lg-font="normal 700 50px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 700 40px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 24px 0px"
					text-transform="uppercase"
				>
					WHY CHOOSE US
				</Text>
				<Text
					text-align="left"
					lg-font="normal 600 16px/1.5 &quot;Varta&quot;, sans-serif"
					lg-margin="80px 0px 0px 0px"
					md-margin="0px 0px 0px 0px"
					margin="40px 0px 0px 0px"
					color="--light"
					font="--lead"
				>
					Professionalism and Experience: We have many years of experience and specialize in repairing devices of any complexity.
					<br />
					<br />
					Service Speed: We value your time and ensure quick and high-quality repairs.
					<br />
					<br />
					Quality Guarantee: We provide a warranty on all types of work and use only high-quality spare parts.
					<br />
					<br />
					Individual Approach: Every client is unique to us, and we always strive to find the best solution for each case.
					<br />
					<br />
					Affordable Prices: We offer competitive prices with no hidden fees.
				</Text>
			</Box>
		</Section>
		<Section
			padding="140px 0 140px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://uploads.quarkly.io/66a246642fc7070018ba356d/images/5355918_dfa1.jpg?v=2024-07-25T12:51:47.477Z) center center/cover no-repeat"
			min-height="40vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline3"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Entrust the repair of your devices to professionals. "Gadget Guru Repairs" is your reliable partner in the world of technology.
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
				>
					We will provide you with quality, reliability, and speed. Contact us today!
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Contact Us
				</Link>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text1" />
			<Override slot="box2" />
			<Override slot="box1" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="box3" />
			<Override slot="box" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});